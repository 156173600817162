import { Card, Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function Help() {
  const [iframeHeight, setIframeHeight] = useState(window.innerHeight);

  // Update iframe height on window resize
  useEffect(() => {
    const handleResize = () => setIframeHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const avail_sites = useSelector(state => state.sites.avail_sites);
  const selectedSite = useSelector(state => state.sites.selectedSite);
  const confluenceLink = avail_sites.find((site) => site.name === selectedSite)?.preferences?.confluence_link;

  return (
    <Card title='Documentation' id="help">
      {confluenceLink ? (
        <iframe
          src={confluenceLink}
          style={{ width: "100%", height: `${iframeHeight - 185}px`, border: "none", backgroundColor: "transparent" }}
        ></iframe>
      ) : (
        <Empty description="No Documentation Available" />
      )}
    </Card>
  );
}
