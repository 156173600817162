import React, { useState, useEffect } from "react";
import { Tooltip as AntToolTip, Skeleton, Spin } from 'antd'
import {
  DatePicker,
  Row,
  Col,
  Typography,
  Button,
  Select,
  Card,
  theme,
  Tag, Space, Progress
} from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment-timezone";
import { CSVLink } from "react-csv";
import { DownloadOutlined, InfoCircleOutlined, ThunderboltOutlined } from "@ant-design/icons";
import "../home.css";
import ChargerModel from "./chargerModel";
import AlarmHistory from "./alarmHistory";
import {
  setFromDate,
  setToDate,
  setDateRange,
  setSelectedGeneratorId,
  setBpValue,
} from '../../../redux/reducers/siteSlice';
import { useDispatch, useSelector } from "react-redux";
import PowerGeneration from "./powerGeneration";
import GeneralPage from "./generalPage";
// import { CameraCard } from "./CameraCard";
import { VERYLOWFUEL, LOWFUEL, REFUELED, TIMEZONES, HEADERS } from "../../../utils/constants";
import { fetchAndFilterGraphData } from "../../../redux/reducers/apis";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

ChartJS.register({
  id: 'customSpacingLegend',
  beforeInit(chart) {
    // Get reference to the original fit function
    const originalFit = chart.legend.fit;

    // Override the fit function
    chart.legend.fit = function fit() {
      // Call original function and bind scope in order to use `this` correctly inside it
      originalFit.bind(chart.legend)();
      // Change the height as suggested in another answers
      this.height += 20;
    }
  }
});

const { Option } = Select;

const { RangePicker } = DatePicker;

let latestValueDatetime = null;
let formattedLatestValueDatetime = null;
let largestValue = 100;

// This is the main component of the dashboard for rendering all component

function FuelHistChart() {
  const dispatch = useDispatch();

  const responsiveColDownload = {
    xxl: 4,
    xl: 4,
    lg: 4,
    md: 8,
    sm: 8,
    xs: 24,
  };

  const responsiveColDaySelection = {
    xxl: 10,
    xl: 10,
    lg: 10,
    md: 24,
    sm: 24,
    xs: 24,
  };

  const responsiveCol = { xxl: 8, xl: 8, lg: 8, md: 8, sm: 24, xs: 24 };
  const [generatorList, setGeneratorList] = useState([]);



  const bpSitehistory = useSelector(state => state.sites.bpSitehistory);
  const bpValue = useSelector(state => state.sites.bpValue);
  const avail_sites = useSelector(state => state.sites.avail_sites);
  const selectedSite = useSelector(state => state.sites.selectedSite);
  const fromDate = useSelector(state => state.sites.fromDate);
  const toDate = useSelector(state => state.sites.toDate);
  const selectedGeneratorId = useSelector(state => state.sites.selectedGeneratorId);
  const selectedCustomerName = useSelector(state => state.customer.selectedCustomerName);
  const dateRange = useSelector(state => state.sites.dateRange);
  const dataVisible = useSelector(state => state.sites.dataVisible);
  const isLoadingGraphData = useSelector(state => state.sites.isLoadingGraphData);
  console.log("isLoadingGraphData", isLoadingGraphData);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState("Today");
  const [selectedTimezone, setSelectedTimezone] = useState("UTC");
  const generatorListfromAPI = useSelector(state => state.sites.generatorList);
  const isLoadingGeneratorList = useSelector(state => state.sites.isLoadingGeneratorList);
  const handleEquipmentChange = (value) => {
    dispatch(setSelectedGeneratorId(value));
    // setSelectedEquipmentId(value);
  };


  let maxValue = -Infinity;

  useEffect(() => {
    if (generatorListfromAPI) {
      setGeneratorList(generatorListfromAPI
        .filter(info => info.equipment_name !== null && !['EV Charger', 'EV_Charger'].includes(info.equipment_name)) // Filter out null equipment_name
        .map(info => ({
          value: info.equipment_id,
          label: info.equipment_name,
        })))
    }
  }, [generatorListfromAPI])

  for (const obj of bpSitehistory) {
    // For Fuel level only we are setting the max value as 100 in graph y axis.
    if (bpValue === "Fuel Level") {
      largestValue = 100;
    } else {
      if (obj.value > maxValue) {
        maxValue = obj.value;
      }
      largestValue = maxValue > 0 ? maxValue : 100;
    }
  }

  const options = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          boxHeight: 10, // Adjust the height of legend labels
          boxWidth: 10, // Adjust the width of legend labels
        },
      },
      title: {
        display: true,
        text: getTitle(),
      },
    },
    scales: {
      x: {
        ticks: {
          precision: 0,
          maxTicksLimit: 10, // Set the maximum number of ticks you want on the x-axis
        },
        //   display: true,
        max: setMaxValueForXaxis(),
      },
      y: {
        ticks: {
          precision: 1,
          maxTicksLimit: 10, // Set the maximum number of ticks you want on the y-axis
        },
        // display: true,
        min: 0,
        max: largestValue,
      },
    },
  };


  function getTitle() {
    // Add your conditional logic here to determine the title based on certain conditions
    if (dataVisible === "Fuel") {
      return "Fuel History Chart";
    } else {
      return "BP History Chart";
    }
  }

  // Updating timezone for a particular site for showing the data in graph
  const updateTimezone = (timezone) => {
    setSelectedTimezone(timezone);
  };

  // Showing the labels in x axis
  const formattedFuelHistory = bpSitehistory?.map((hist) => {
    const formattedDatetime = moment(hist.value_datetime)
      .tz(selectedTimezone)
      .format("MM-DD HH:mm");
    return formattedDatetime;
  });

  // 1. Find the maximum value_datetime to set the max point in x axis

  function setMaxValueForXaxis() {
    for (const dataPoint of bpSitehistory) {
      const valueDatetime = new Date(dataPoint.value_datetime);
      if (!latestValueDatetime || valueDatetime > latestValueDatetime) {
        latestValueDatetime = valueDatetime;
      }
    }
    // Convert the maximum value_datetime to the desired format (e.g., "MM-DD HH:mm")
    return (formattedLatestValueDatetime = moment(latestValueDatetime)
      .tz(selectedTimezone)
      .format("MM-DD HH:mm"));
  }

  const data = {
    labels: formattedFuelHistory,
    datasets: [
      {
        label: bpValue,
        data: bpSitehistory
          .map(value => value.value === -99 ? null : value.value),
        borderColor: "blue",
        backgroundColor: "blue",
        pointBackgroundColor: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          return value <= VERYLOWFUEL
            ? "red" // draw peak values in red
            : value <= LOWFUEL &&
              value > VERYLOWFUEL
              ? "orange"
              : value >= REFUELED
                ? "green"
                : "blue";
        },
        pointBorderColor: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          return value <= VERYLOWFUEL
            ? "red" // draw peak values in red
            : value <= LOWFUEL &&
              value > VERYLOWFUEL
              ? "orange"
              : value >= REFUELED
                ? "green"
                : "blue";
        },
      },
      ...(bpValue === "Fuel Level"
        ? [
          {
            label: "Very low",
            borderColor: "red",
            backgroundColor: "red",
          },
          {
            label: "low",
            borderColor: "orange",
            backgroundColor: "orange",
          },
          {
            label: "Refueled",
            borderColor: "green",
            backgroundColor: "green",
          },
        ]
        : []),
    ],
  };

  // Setting page title for downloaded csv
  const fileName = "fuel_history";
  useEffect(() => {
    dispatch(setBpValue('Fuel Level'));
  });

  const [timePeriodChangeFlag, setTimePeriodChangeFlag] = useState(false);

  // Setting changed time period in a state variable
  const handleTimePeriodChange = (timePeriod) => {
    setTimePeriodChangeFlag(true);
    setSelectedTimePeriod(timePeriod);
  };

  // Setting the selectedTimeperiod to today while changing the card
  useEffect(() => {
    setSelectedTimePeriod("Today");
  }, [selectedSite]);

  // Calling getDateTimeRange function for setting from date and To Date
  useEffect(() => {
    if (timePeriodChangeFlag == true) {
      if (selectedTimePeriod != "date") {
        getDateTimeRange(selectedTimePeriod);
      }
      setTimePeriodChangeFlag(false);
    }
  }, [selectedTimePeriod]);

  // Filtering date wise data while changing timezone dropdown
  function getDateTimeRange(option) {
    var currentDate = new Date();
    var startDate, endDate;

    switch (option) {
      case "Last 24 hours":
        startDate = new Date(currentDate);
        startDate.setHours(currentDate.getHours() - 24);
        endDate = new Date(currentDate);
        break;
      case "Today":
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59, 999);
        break;
      case "Last 7 Days":
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - 7);
        endDate = new Date(currentDate);
        break;
      case "Last 30 Days":
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - 30);
        endDate = new Date(currentDate);
        break;
      case "This Week":
        var currentDayOfWeek = currentDate.getDay();
        var startOffset = (currentDayOfWeek === 0 ? -6 : 1) - currentDayOfWeek;
        startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() + startOffset);
        endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);
        break;
      case "This Month":
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59, 999);
        break;
      default:
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        endDate = new Date(currentDate);
        break;
    }

    // Setting fromDate and toDate for the API call to plot graph data based on the time period changes
    const fromDate = startDate.toISOString();
    const toDate = endDate.toISOString();

    dispatch(setFromDate(fromDate));
    dispatch(setToDate(toDate));
  }

  // Called when the user select time period as range
  const filterDateTime = (range) => {
    dispatch(setDateRange(range));
    // Returns a string representing this date in the date time string format,
    const fromDate = range[0].toISOString();
    const toDate = range[1].toISOString();

    dispatch(setFromDate(fromDate));
    dispatch(setToDate(toDate));
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    if (avail_sites && !([undefined, null, ''].includes(selectedGeneratorId)) && selectedSite) {
      dispatch(fetchAndFilterGraphData({ selectedCustomerName, avail_sites, selectedSite, fromDate, toDate, bpValue, selectedGeneratorId }));
    }
  }, [fromDate, toDate, selectedSite, bpValue, selectedGeneratorId]);


  return (
    <div>
      <Row justify="start" style={{ paddingBottom: "10px" }}>
        <Col span={24}>
          <Typography.Title level={3} type="secondary">Fuel History</Typography.Title>

          <Card style={{ background: colorBgContainer, marginTop: '15px', height: '100%' }}>
            <Row justify="start" className="rowGraph">
              <Col span={24}>
                <Row>
                  <Col {...responsiveCol}>
                    {((generatorList && generatorList[0]) || isLoadingGeneratorList) && <Select
                      value={selectedGeneratorId}
                      style={{ width: 120 }}
                      options={generatorList}
                      loading={isLoadingGeneratorList}
                      onChange={handleEquipmentChange}
                    />}
                  </Col>
                  <Col {...responsiveCol}>
                    <Typography.Paragraph style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', alignItems: 'center' }}>
                      Filter by:
                      <Select
                        value={selectedTimePeriod}
                        style={{ width: 150 }}
                        onChange={handleTimePeriodChange}
                        options={[
                          { value: "Last 24 hours", label: "Last 24 hours" },
                          { value: "Today", label: "Today" },
                          { value: "Last 7 Days", label: "Last 7 Days" },
                          { value: "Last 30 Days", label: "Last 30 Days" },
                          { value: "This Week", label: "This Week" },
                          { value: "This Month", label: "This Month" },
                          { value: "date", label: "Custom" },
                        ]}
                      />
                      {selectedTimePeriod === "date" && (
                        <RangePicker
                          showTime
                          value={dateRange}
                          format="MM/DD/YYYY HH:mm"
                          onChange={filterDateTime}
                          style={{ width: 300 }}
                        />
                      )}
                    </Typography.Paragraph>
                  </Col>

                  <Col {...responsiveCol}>
                    <Typography.Paragraph>
                      Timezone: &nbsp;&nbsp;
                      <Select
                        defaultValue={selectedTimezone}
                        style={{ width: 120 }}
                        onChange={updateTimezone}
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {TIMEZONES.map((timezone) => (
                          <Option key={timezone.value} value={timezone.value} label={timezone.label}>
                            {timezone.label}
                          </Option>
                        ))}
                      </Select>
                    </Typography.Paragraph>
                  </Col>
                  {/* <Col {...responsiveColDownload}>
                    <CSVLink
                      data={bpSitehistory}
                      headers={HEADERS}
                      className="csvLink"
                      filename={fileName}
                    >
                      <Button type="primary">
                        <DownloadOutlined style={{ fontSize: "20px" }} />
                        Download Fuel history
                      </Button>
                    </CSVLink>
                  </Col> */}
                </Row>
              </Col>
              {!isLoadingGraphData && !isLoadingGeneratorList && <div className="w-full">
                {bpSitehistory.length > 0 ? (
                  <Col
                    span={24}
                    style={{
                      height: "700px",
                      width: "100%",
                      overflow: "auto",
                    }}
                  >
                    <Line options={options} data={data} className="lineGraph" />
                  </Col>
                ) : (
                  <Row justify="center">
                    <Col xs={24} sm={22} md={20} lg={18} xl={16} className="loadingColGraph">
                      <Typography.Title level={2} type="secondary">
                        {selectedTimePeriod == "date"
                          ? "No fuel history found for the given date"
                          : `No fuel history found for ${selectedTimePeriod}`}
                      </Typography.Title>
                    </Col>
                  </Row>
                )}
              </div>}
              {(isLoadingGraphData || isLoadingGeneratorList) &&
                <div style={{ width: "100%" }}>
                  <Spin size="large" >
                    <Row justify="center">
                      <Col xs={24} sm={22} md={20} lg={18} xl={16} className="loadingColGraph">
                        <Typography.Title level={2} type="secondary">Preparing chart. Please wait</Typography.Title>
                      </Col>
                    </Row>
                  </Spin>
                </div>}
            </Row>
          </Card>

        </Col>
      </Row>
      {/* {
        dataVisible === "Camera2" && (
          <Col span={24}>
            <Row gutter={[1, 1]} justify="space-between" style={{ marginTop: '20px' }}>
              <Col span={4}>
                <Card hoverable>
                  <AntToolTip title="Total Cameras">
                    <Typography.Text strong style={{ color: '#848884' }}>Total Chargers <InfoCircleOutlined /></Typography.Text>
                  </AntToolTip>
                  <Typography.Title level={1} style={{ fontSize: '34px' }}>
                    <Space>
                      <ThunderboltOutlined />
                      <span style={{ fontWeight: '700' }}>5</span>
                    </Space>
                  </Typography.Title>
                  <Col span={24}>
                    <Progress strokeLinecap="butt" percent={100} strokeColor="#0d98ba" showInfo={false} />
                  </Col>
                  <Col span={24}>
                    <Typography.Text>100%</Typography.Text>
                  </Col>
                </Card>
              </Col>
              <Col span={4}>
                <Card hoverable>
                  <AntToolTip title="Total Events">
                    <Typography.Text strong style={{ color: '#848884' }}>Total Events <InfoCircleOutlined /></Typography.Text>
                  </AntToolTip>

                  <Typography.Title level={1} style={{ fontSize: '34px' }}>
                    <Space>
                      <span style={{ fontWeight: '700' }}>0</span>
                    </Space>
                  </Typography.Title>
                  <Col span={24}>
                    <Progress strokeLinecap="butt" percent={0} showInfo={false} />
                  </Col>
                  <Col span={24}>
                    <Typography.Text>0%</Typography.Text>
                  </Col>
                </Card>
              </Col>
              <Col span={4}>
                <Card hoverable>
                  <AntToolTip title="Thumbnails">
                    <Typography.Text strong style={{ color: '#848884' }}>Thumbnails <InfoCircleOutlined /></Typography.Text>
                  </AntToolTip>
                  <Typography.Title level={1} style={{ fontSize: '34px' }}>
                    <Space>
                      <span style={{ fontWeight: '700' }}>0</span>
                    </Space>
                  </Typography.Title>
                  <Col span={24}>
                    <Progress strokeLinecap="butt" percent={0} showInfo={false} />
                  </Col>
                  <Col span={24}>
                    <Typography.Text>0%</Typography.Text>
                  </Col>
                </Card>
              </Col>
              <Col span={4}>
                <Card hoverable>
                  <Typography.Title level={1} style={{ fontSize: '34px' }}>
                    <Space>
                      <span style={{ fontWeight: '700' }}>0</span>
                    </Space>
                  </Typography.Title>
                  <Col span={24}>
                    <Progress strokeLinecap="butt" percent={0} showInfo={false} />
                  </Col>
                  <Col span={24}>
                    <Typography.Text>0%</Typography.Text>
                  </Col>
                </Card>
              </Col>
              <Col span={4}>
                <Card hoverable>
                  <Typography.Title level={1} style={{ fontSize: '34px' }}>
                    <Space>
                      <span style={{ fontWeight: '700' }}>5</span>
                    </Space>
                  </Typography.Title>
                  <Col span={24}>
                    <Progress strokeLinecap="butt" percent={100} strokeColor="#0d98ba" showInfo={false} />
                  </Col>
                  <Col span={24}>
                    <Typography.Text>100%</Typography.Text>
                  </Col>
                </Card>
              </Col>
            </Row>
          </Col>
        )
      }
      {
        dataVisible === "CameraOld" && (
          <Row justify="start" style={{ paddingBottom: "10px" }}>
            <Col span={24}>
              
              <Row justify="start" className="rowGraph">
                <Col span={24}>
                  <Row
                    justify="start"
                    className="rowGraph"
                    style={{ height: "calc(90vh - 19rem)" }}
                  >
                    <Col span={12}>
                      <Typography.Title level={3} type="secondary">Drivers Side</Typography.Title>
                      <Typography.Paragraph className="box-border">
                        No signal!
                      </Typography.Paragraph>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={3} type="secondary">Passengers Side</Typography.Title>
                      <Typography.Paragraph className="box-border">
                        No signal!
                      </Typography.Paragraph>
                    </Col>
                    <Col span={12}>
                      <Typography.Title level={3} type="secondary">Back</Typography.Title>
                      <Typography.Paragraph className="box-border">
                        No signal!
                      </Typography.Paragraph>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        )
      } */}

    </div >
  );
}
export default FuelHistChart;
