import React from 'react';
import { Col, Row, Card, Typography, Progress, Space, Tooltip } from 'antd';
import { InfoCircleOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { selectAllSiteDatas } from '../../../redux/reducers/siteSlice';
import './power.css';

const ChargerCard = ({ title, icon, tooltip, value, percent }) => {



    return (
        <Card hoverable className='customBorder'>
            <Card.Grid hoverable className='w-full h-full'>
                <Tooltip title={tooltip}>
                    <Typography.Text strong className='text-base'>
                        {title} <InfoCircleOutlined />
                    </Typography.Text>
                </Tooltip>
                <Typography.Title level={1} className='text-34'>
                    <Space>
                        {icon && <ThunderboltOutlined />}
                        <span className='font-bold'>{value}</span>
                    </Space>
                </Typography.Title>
                {/*<Col span={24}>*/}
                {/*    <Progress strokeLinecap="butt" percent={percent}strokeColor="#0d98ba" showInfo={false} />*/}
                {/*</Col>*/}
                {/*<Col span={24}>*/}
                {/*    <Typography.Text>{`${percent}%`}</Typography.Text>*/}
                {/*</Col>*/}
            </Card.Grid>
        </Card>
    );
};
const PowerGeneratedStatus = () => {
    const siteData = useSelector(selectAllSiteDatas);
    const selectedSite = siteData.selectedSite;
    const avail_sites = siteData.avail_sites;
    const preferences = avail_sites.find((site) => site.name === selectedSite)?.preferences;
    // Check if preferences.power.powercards is defined and not empty
    const shouldRenderKWHGenerated = preferences?.power?.powercards?.includes("kWhGenerated");
    const shouldRenderKWHConsumed = preferences?.power?.powercards?.includes("kWhConsumed");
    const shouldRenderEngineHours = preferences?.power?.powercards?.includes("EngineHours");
    const shouldRenderNextMaintenance = preferences?.power?.powercards?.includes("NextMaintenance");

    return (
        <Col span={24}>
            <Row justify="space-around" gutter={[16, 16]}>
                {[
                    shouldRenderKWHGenerated && {
                        title: "KWH Generated",
                        tooltip: "KWH Generated info",
                        value: 25,
                        percent: 20,
                        icon: <ThunderboltOutlined />
                    },
                    shouldRenderKWHConsumed && {
                        title: "KWH Consumed",
                        tooltip: "KWH Consumed info",
                        value: 20,
                        percent: 20,
                    },
                    shouldRenderEngineHours && {
                        title: "Engine Hours/ Month",
                        tooltip: "Engine Hours this month info",
                        value: 100,
                        percent: 100,
                    },
                    shouldRenderNextMaintenance && {
                        title: "Next Maintenance/Days",
                        tooltip: "Next Maintenance/Days info",
                        value: 10,
                        percent: 10,
                    },
                ]
                    .filter(Boolean)
                    .map((cardData, index, array) => (
                        <Col
                            key={index}
                            xs={24}
                            sm={24}
                            md={12}
                            lg={array.length === 0 ? 24 : 24 / array.length}  // Dynamically adjust based on the number of columns
                            xl={array.length === 0 ? 24 : 24 / array.length}  // Ensures equal width for all columns
                        >
                            <ChargerCard
                                title={cardData.title}
                                tooltip={cardData.tooltip}
                                value={cardData.value}
                                percent={cardData.percent}
                                icon={cardData.icon}
                            />
                        </Col>
                    ))}
            </Row>
        </Col>
    );
}

export default PowerGeneratedStatus;