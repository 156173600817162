import {
    ArrowLeftOutlined,
    BellOutlined,
    EnvironmentOutlined,
    InfoCircleOutlined,
    MenuFoldOutlined,
    MenuOutlined,
    MenuUnfoldOutlined,
    PoweroffOutlined,
    ReloadOutlined,
    SolutionOutlined,
    UserOutlined,
    WifiOutlined
} from '@ant-design/icons';
import { Avatar, Breadcrumb, Button, Col, Drawer, Dropdown, Layout, Menu, Row, Select, theme } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AlarmLogo from '../../assets/Icons/alarm.246x256.png';
import GeneralLogo from '../../assets/Icons/book.221x256.png';
import ChargerLogo from '../../assets/Icons/electric-charge.256x256.png';
import FuelLogo from '../../assets/Icons/gas-station.256x237.png';
import HelpLogo from '../../assets/Icons/help-circle.256x256.png';
import IntroLogo from '../../assets/Icons/info-empty.256x256.png';
import LocationLogo from '../../assets/Icons/location-pin.190x256.png';
import nuclearPowergenerationLogo from '../../assets/Icons/nuclear-power-plant.220x256.png';
import ReportsLogo from '../../assets/Icons/report.201x256.png';
import SolarLogo from '../../assets/Icons/solar-panel-sun-thin.234x256.png';
import UserLogo from '../../assets/Icons/user.226x256.png';
import VideoLogo from '../../assets/Icons/video-camera.256x159.png';
import Logo from "../../assets/logo/logo.png";
import LogoSmall from "../../assets/logo/logoSmall.png";
import { setSelectedCustomerName } from '../../redux/reducers/customerSlice';
import { selectAllSiteDatas, setBpValue, setChargerDetailsPageOpen, setDataVisible, setFromDate, setFuelPercentage, setSelectedChargerDetails, setSelectedGeneratorId, setSelectedSiteName, setStarLink, setToDate, setVictronSite } from '../../redux/reducers/siteSlice';
import { getBatteryStatus, initiateLogin } from "../../utils/common";
import { ALARMS, CAMERAS, CHARGERS, COMMUNICATION, FUEL, GENERAL, DOCS, INTRODUCTION, MAPS, POWER_GENERATION, REPORTS, SOLAR, STARLINK } from '../../utils/constants';
import GlMaps from '../Home/components/GlMaps';
import GeneralPage from '../Home/components/generalPage';
import ChargerModel from '../Home/components/chargerModel';
import PowerGeneration from '../Home/components/powerGeneration';
import Solar from '../Home/components/solar';
import Communication from '../Home/components/communication';
import Reports from '../Home/components/reports';
import AlarmHistory from '../Home/components/alarmHistory';
import FuelHistChart from '../Home/components/fuelchart';
import Introduction from '../Home/components/introduction';
import "../Home/home.css";
import { fetchChargerList, fetchSummaryData } from '../../redux/reducers/apis';
import CameraCard from "../Home/components/CameraCard";
import StarLink from '../Home/components/Starlink';
import Help from '../Home/components/help';

const { Header, Sider, Content } = Layout;

// Keep track of the history of clicked menu items
let historyArray = [];

const SummaryDashboard = () => {

    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(false);

    const siteData = useSelector(selectAllSiteDatas);
    const fromDate = useSelector((state) => state.charger.fromDate);
    const toDate = useSelector((state) => state.charger.toDate);
    const customerList = useSelector(state => state.customer.customerList);
    const customerNames = _.map(customerList, "customer")
    const selectedCustomerName = useSelector(state => state.customer.selectedCustomerName);
    const avail_sites = siteData.avail_sites;
    const availableSites = _.map(avail_sites, "name")
    const selectedSite = siteData.selectedSite;
    const bpValueArray = useSelector(state => state.sites.bpValueArray);
    const chargerData = useSelector(state => state.sites.chargerData);
    const isLoadingChargerData = useSelector(state => state.sites.isLoadingChargerData);
    const userInfo = useSelector(state => state.customer.userInfo);
    const presentComponents = avail_sites.find((site) => site.name === selectedSite)?.preferences?.present_components;
    const chargerCurrentStatus = useSelector((state) => state.charger.chargerCurrentStatus);
    const dataVisible = useSelector(state => state.sites.dataVisible);
    const dummyChargerData = useSelector((state) => state.sites.chargerDataDummy);
    const chargerDetailsPageOpen = useSelector(state => state.sites.chargerDetailsPageOpen);

    console.log("chargerDetailsPageOpen", chargerDetailsPageOpen)

    // Notification label
    const notificationLabel = "Notification";

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    // function to create a menu item
    const getItem = (label, key, icon, children, onClick, disabled) => {
        return {
            label,
            key,
            icon,
            children,
            onClick,
            disabled
        };
    };
    // const reactDashboardUrl = process.env.REACT_APP_URL;
    // const signOut = () => {
    //     localStorage.removeItem('user');
    //     const logoutURL = `https://eboostdev.auth.ap-south-1.amazoncognito.com/logout?response_type=code&client_id=377djk1ui7n4u7gi14qon9dga3&redirect_uri=${reactDashboardUrl}&state=MlG9gMyakT-uyV0h1EFrYQ&scope=openid%20profile%20email`;
    //     window.location.href = logoutURL;
    // };


    const protocol = process.env.REACT_APP_PROTOCOL;
    const url = process.env.REACT_APP_API_URL;
    const baseUrl = protocol + url;
    let cdata = new CameraCard();

    const signOut = () => {

        axios
            .post(`${baseUrl}oauth/logout/`, { withCredentials: true })

            .then((data) => {
                console.log("<<< hit logout API! >>>");
                // navigate('/logout')
                localStorage.removeItem('user');
                initiateLogin()
            })
            .catch((error) => {
                console.log(error);
            });
    };


    const handleCustomerChange = (e) => {
        dispatch(setSelectedCustomerName(e.key))
    };

    const handleSelectCustomerChange = (value) => {
        dispatch(setSelectedGeneratorId(''));
        dispatch(setSelectedCustomerName(value))
        dispatch(setFuelPercentage(null))
    }

    const handleSiteChange = (e) => {
        dispatch(setSelectedSiteName(e.key));

        //fetch victron link attached to the selected site
        dispatch(setVictronSite(avail_sites.find(obj => obj.name === e.key)?.victron_link));
    };

    const handleSelectSiteChange = (value) => {
        dispatch(setSelectedGeneratorId(''));
        dispatch(setSelectedSiteName(value));
        dispatch(setFuelPercentage(null))
        dispatch(setChargerDetailsPageOpen('false'));

        //fetch victron link attached to the selected site
        dispatch(setVictronSite(avail_sites.find(obj => obj.name === value)?.victron_link));
        dispatch(setStarLink(avail_sites.find(obj => obj.name === value)?.communication_link));
    }

    useEffect(() => {
        if (userInfo.show_intro === true) {
            dispatch(setDataVisible(INTRODUCTION));
        } else {
            dispatch(setDataVisible(GENERAL));
        }
    }, [userInfo, selectedCustomerName]);

    useEffect(() => {
        // Check if userInfo.show_intro is true and insert the Introduction item
        if (userInfo.show_intro === true) {
            storeClickedItemHistory(INTRODUCTION);
        } else {
            storeClickedItemHistory(GENERAL);
        }
    }, []); // Empty dependency array ensures this effect runs only once after component mount

    const storeClickedItemHistory = (item) => {

        // Check if the last item in the history array is not equal to the current item
        if (historyArray.length === 0 || historyArray[historyArray.length - 1] !== item) {
            // Add the clicked item to the history
            historyArray.push(item);
        }
    }

    const handleIntroductionClick = (item) => {
        storeClickedItemHistory(item.key);
        dispatch(setDataVisible(item.key));
    }

    const handleGeneralClick = (item) => {
        storeClickedItemHistory(item.key);
        dispatch(setDataVisible(item.key));
    };

    const [selectedCharger, setSelectedCharger] = useState([]);

    const handleChargerClick = (item) => {
        setSelectedCharger(item.key);
        dispatch(setChargerDetailsPageOpen('false'));
        // Set dataVisible to "Chargers"
        dispatch(setDataVisible(item.key));
        storeClickedItemHistory(item.key);
    };

    const handleChargerMenuItemClick = (e) => {
        let selectedCharger = chargerData.filter((data) => Number(data.id) === Number(e.key));
        const chargerId = _.get(selectedCharger, "0.id", "");
        const currentChargerStatus = _.get(chargerCurrentStatus, chargerId, {});
        const selectedChargerStatus = _.get(currentChargerStatus, 'status', 'Available');
        const chargerStatus = getBatteryStatus(selectedChargerStatus)
        dispatch(setSelectedChargerDetails({ ...selectedCharger, currentChargerStatus: chargerStatus }))
        dispatch(setChargerDetailsPageOpen('true'));
        dispatch(setDataVisible(CHARGERS));
        storeClickedItemHistory(e.key);
    };

    const handleMapClick = (item) => {
        dispatch(setDataVisible(item));
    };

    const handlePowerGenerationClick = (item) => {
        dispatch(setFromDate());// Resetting the fromdate and todate for getting today's data while changing the tab
        dispatch(setToDate());
        dispatch(setBpValue(bpValueArray[0]));
        dispatch(setDataVisible(item));
    };

    const handleFuelClick = (item) => {
        dispatch(setFromDate()); // Resetting the fromdate and todate for getting today's data while changing the tab
        dispatch(setToDate());
        dispatch(setBpValue("Fuel Level"));
        dispatch(setDataVisible(item));
    };


    const handleSolarClick = (item) => {
        dispatch(setDataVisible(item));
    };

    const handleStarlinkClick = (item) => {
        dispatch(setDataVisible(item));
    };

    const handleCommunicationClick = (item) => {
        dispatch(setDataVisible(item.key));
    };

    const handleReportsClick = (item) => {
        dispatch(setBpValue(bpValueArray[0]));
        dispatch(setDataVisible(item));
    };

    const handleCamerasClick = (item) => {
        dispatch(setDataVisible(item));
    }

    const handleAlarmsClick = (item) => {
        dispatch(setDataVisible(item));
    }

    const handleHelpClick = (item) => {
        dispatch(setDataVisible(item));

    }

    let chargerMenuItems = [];
    let chargerMenuItemsDummy = [];
    const userMenuItems = customerList.map(cust => getItem(cust.customer, cust.customer));
    const sitesMenuItems = avail_sites.map(site => getItem(site.name, site.name));
    chargerMenuItems = chargerData.map(data => getItem(data.charger_title, data.id, null, null, null, isLoadingChargerData)); // Extract names from chargerData   
    chargerMenuItemsDummy = dummyChargerData.map(data => getItem(data.name, data._id, null, null, null, false));

    // Filter chargerData based on the selected site
    // const selectedSiteChargers = chargerData.filter(charger => charger.site === selectedSite);

    // // Create menu items for the filtered chargers
    // const chargerMenuItems = selectedSiteChargers.map(chargerName => getItem(chargerName, chargerName));
    // const chargerMenuItems = selectedSiteChargers.map(charger => getItem(charger.charger_title, charger.charger_title, null, null, () => handleChargerClick(charger.charger_title)));

    const VersionItem = () => (
        <>
            <div className='primaryColor'>
                Ver 2.0 API 2.45
            </div>
        </>
    );

    const getIconForPresentItem = (item) => {
        if (item === POWER_GENERATION) {
            return <img src={nuclearPowergenerationLogo} alt="power" className='h-18' />;
        } else if (item === FUEL) {
            return <img src={FuelLogo} alt="fuel" className='h-18' />;
        } else if (item === SOLAR) {
            return <img src={SolarLogo} alt="solar" className='h-18' />;
        } else if (item === STARLINK) {
            return <WifiOutlined className='h-18' />;
        } else if (item === REPORTS) {
            return <img src={ReportsLogo} alt="report" className='h-18' />;
        } else if (item === CAMERAS) {
            return <img src={VideoLogo} alt="camera" className='h-13' />;
        } else if (item === ALARMS) {
            return <img src={AlarmLogo} alt="alarm" className='h-18' />;
        } else if (item === MAPS) {
            return <EnvironmentOutlined />;
        }
        else if (item === DOCS) {
            return <InfoCircleOutlined className='h-18' />;
        }
    }

    const handleMenuItemClick = (item) => {
        storeClickedItemHistory(item);
        if (item === POWER_GENERATION) {
            handlePowerGenerationClick(item);
        } else if (item === FUEL) {
            handleFuelClick(item);
        } else if (item === SOLAR) {
            handleSolarClick(item);
        } else if (item === STARLINK) {
            handleStarlinkClick(item);
        } else if (item === REPORTS) {
            handleReportsClick(item);
        } else if (item === CAMERAS) {
            handleCamerasClick(item);
        } else if (item === ALARMS) {
            handleAlarmsClick(item);
        } else if (item === MAPS) {
            handleMapClick(item);
        }
        else if (item === DOCS) {
            handleHelpClick(item);
        }
    }

    const items = [
        // {
        //     label: 'Customer',
        //     key: '1',
        //     icon: <img src={UserLogo} className='h-18' />,
        //     children: userMenuItems,
        //     onClick: handleCustomerChange
        // },
        // {
        //     label: 'Sites',
        //     key: '2',
        //     icon: <img src={LocationLogo} alt="Location" className='h-18' />,
        //     children: sitesMenuItems,
        //     onClick: handleSiteChange
        // },
        // getItem('Introduction', 'Introduction', <img src={IntroLogo} alt="introduction" className='h-18' />, null, handleIntroductionClick),
        getItem('General', 'General', <img src={GeneralLogo} alt="general" className='h-18' />, null, handleGeneralClick),

    ];

    presentComponents?.forEach((item, index) => {
        if (item === CHARGERS) {
            items.push({
                label: 'Chargers',
                key: `Chargers`,
                icon: <img src={ChargerLogo} alt="charger" className='h-18' />,
                onTitleClick: handleChargerClick,
                onClick: handleChargerMenuItemClick, // Pass key and chargerMenuItems key array
                children: chargerMenuItems, // Assuming chargerMenuItems contains children
            })
        } else {
            items.push({
                label: item,
                key: item,
                icon: getIconForPresentItem(item), // a function to get the icon for each item
                onClick: () => handleMenuItemClick(item), // Assuming you have a common click handler for menu items
            });
        }
    });

    // Check if userInfo.show_intro is true and insert the Introduction item
    if (userInfo.show_intro === true) {
        items.splice(0, 0, getItem('Introduction', 'Introduction', <img src={IntroLogo} alt="introduction" className='h-18' />, null, handleIntroductionClick));
    }

    const logo = collapsed ? LogoSmall : Logo;

    const widgetMenu = (
        <Menu theme="dark" mode="inline">
            {/* Avatar and Username Row */}
            <div className='centered16'>
                <Avatar size="large" className='avatar' icon={<UserOutlined />} />
                <div className='truncate'>
                    <div className='text-base font-bold'>
                        {userInfo.first_name} {userInfo.last_name}
                    </div>
                </div>
            </div>
            {/* View Profile Item */}
            <Menu.Item key="profile" icon={<SolutionOutlined />}>
                View Profile
            </Menu.Item>
            <Menu.Item key='Introduction' onClick={handleIntroductionClick} icon={<img src={IntroLogo} alt="introduction" className='h-13' />}>
                Introduction
            </Menu.Item>
            <Menu.Item key="logout" icon={<PoweroffOutlined />} onClick={signOut}>
                Log Out
            </Menu.Item>
        </Menu>
    );

    useEffect(() => {
        historyArray = [GENERAL];
    }, [selectedSite, selectedCustomerName]);

    const handleBackButtonClick = () => {
        const isSerialNumberPresent = chargerData.some(charger => Number(charger.id) === Number(historyArray[historyArray.length - 2]));
        let selectedCharger = chargerData.filter((data) => Number(data.id) === Number(historyArray[historyArray.length - 2]));
        if (isSerialNumberPresent) {
            historyArray.pop();
            dispatch(setSelectedChargerDetails(selectedCharger))
            dispatch(setChargerDetailsPageOpen('true'));
            dispatch(setDataVisible(CHARGERS));
        }
        else if (historyArray[historyArray.length - 1] === GENERAL) {
            dispatch(setDataVisible(GENERAL))
        }
        else {
            dispatch(setDataVisible(historyArray[historyArray.length - 2]));
            if (historyArray[historyArray.length - 2] === CHARGERS) {
                dispatch(setChargerDetailsPageOpen('false'));
            }
            historyArray[historyArray.length - 1] !== GENERAL && historyArray.pop();
        }
    };


    // / If the popped item is the same as dataVisible and there are only two items in the array
    //     if (historyArray.length <= 1) {
    //         //Check whether the historyArray contains the charger menu item, 
    //         // This is a worst case scenario,If future the arrays last item is charger menu, then it will handle
    //         const isSerialNumberPresent = chargerData.some(charger => charger.id === historyArray[0]);
    //         if (isSerialNumberPresent) {
    //             let selectedCharger = chargerData.filter((data) => data.id === historyArray[0]);
    //             dispatch(setSelectedChargerDetails(selectedCharger))
    //             dispatch(setChargerDetailsPageOpen('true'));
    //             dispatch(setDataVisible(CHARGERS));
    //         } else {
    //             // Set dataVisible to the first item without removing it from the array
    //             dispatch(setDataVisible(historyArray[0]));

    //         }
    //     } else {
    //         const previousItem = historyArray.pop();
    //         const isSerialNumberPresent = chargerData.some(charger => charger.id === previousItem);
    //         if (isSerialNumberPresent) {
    //             let selectedCharger = chargerData.filter((data) => data.id === previousItem);
    //             dispatch(setSelectedChargerDetails(selectedCharger))
    //             dispatch(setChargerDetailsPageOpen('true'));
    //             dispatch(setDataVisible(CHARGERS));
    //         } else if (previousItem == CHARGERS) {
    //             dispatch(setChargerDetailsPageOpen('false')); //show charger list page instead of charger section
    //             dispatch(setDataVisible(previousItem));
    //         } else {
    //             dispatch(setDataVisible(previousItem));
    //             if (historyArray.length === 0) {
    //                 historyArray.push(previousItem);
    //             }
    //         }

    //     }

    const handleReloadBtnClick = () => {
        dispatch(fetchChargerList({ selectedCustomerName, avail_sites, selectedSite }));
        dispatch(fetchSummaryData({ selectedCustomerName, selectedSite, avail_sites, fromDate, toDate }));
    }

    //Set the customer dropdown as not clickable when there is only one customer
    const isDisabled = customerNames.length === 1;
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const siderContent = (
        <>
            {!isMobile && <div className='imageDiv'>
                <img
                    src={logo}
                    alt="logo"
                    className='actualImage'
                />
            </div>}
            <div className="selectDiv">
                <Select
                    value={selectedCustomerName}
                    showSearch
                    options={customerList.map(item => ({ label: item.name, value: item.customer }))}
                    suffixIcon={<img src={UserLogo} className='h-18' />}
                    onChange={handleSelectCustomerChange}
                    disabled={isDisabled}
                    className={isDisabled ? 'insideSelect select-disabled' : 'insideSelect'}
                />
            </div>
            <div className="selectDiv">
                <Select
                    value={selectedSite}
                    showSearch
                    options={availableSites.map(item => ({ label: item, value: item }))}
                    className='insideSelect'
                    suffixIcon={<img src={LocationLogo} className='h-18' />}
                    onChange={handleSelectSiteChange}
                />
            </div>
            <Menu
                theme="light"
                mode="inline"
                defaultSelectedKeys={[selectedCustomerName, selectedSite, dataVisible]}
                selectedKeys={[selectedCustomerName, selectedSite, dataVisible, selectedCharger]}
                items={items}
            />
            <div className='versionDiv'>
                <VersionItem />
            </div>
        </>
    );

    const handleSiderClose = () => {
        setCollapsed(!collapsed);
    };

    return (
        <Layout>
            {isMobile ? (
                <>
                    {/* <Button type="primary" onClick={showDrawer} icon={<MenuOutlined />} /> */}
                    <Drawer
                        title={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Button
                                    type="text"
                                    icon={collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                                    onClick={() => handleSiderClose()}
                                    className='mr-10 text-base'
                                />
                                <div>Menu</div>
                            </div>
                            <div className='imageDiv'>
                                <img
                                    src={logo}
                                    alt="logo"
                                    className='actualImage'
                                />
                            </div>
                        </div>}
                        placement="left"
                        closable={false}
                        onClose={() => setCollapsed(!collapsed)}
                        visible={collapsed}
                    >
                        {siderContent}
                    </Drawer>
                </>
            ) : (
                <Sider trigger={null} collapsible collapsed={collapsed} theme="light" className='sider'>
                    {siderContent}
                </Sider>

            )}
            <Layout>
                <Header className='header'
                    style={{
                        background: colorBgContainer
                    }}
                >

                    <div className='collapseDiv'>
                        <Button
                            type="text"
                            icon={collapsed ? isMobile ? <MenuFoldOutlined /> : <MenuUnfoldOutlined /> : isMobile ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                            onClick={() => handleSiderClose()}
                            className='mr-10 text-base'
                        />
                        {/* <div className='centerLogo' style={{ margin: '-50px' }}>
                        <img
                            src={Logo}
                            height="40px"
                            alt="logo"
                        />
                    </div> */}
                        {/* <Typography.Text strong style={{ color: '#007791', fontSize: '24px' }}>Realm management system</Typography.Text> */}
                        <div>
                            <ArrowLeftOutlined
                                onClick={handleBackButtonClick}
                                className={`mainBackButton ${historyArray.length === 1 && dataVisible === historyArray[0] ? 'disabled' : ''}`}
                                style={{ cursor: historyArray.length === 1 && dataVisible === historyArray[0] ? 'not-allowed' : 'pointer' }}
                            />
                        </div>
                        {dataVisible === CHARGERS && chargerDetailsPageOpen === "false" && <ReloadOutlined onClick={handleReloadBtnClick} className='pl-10' />}
                        <div className='noShow p-10'>
                            <Breadcrumb items={[
                                { title: selectedCustomerName },
                                { title: selectedSite },
                                { title: dataVisible }
                            ]} />

                        </div>
                    </div>
                    <div className='collapseDiv'>
                        {/* Notification Icon and Text */}
                        <Button
                            type="text"
                            icon={<BellOutlined className='text-base' />}
                            className='mr-10 text-base'
                        >
                            <span className={collapsed ? '' : 'noShow'}>{notificationLabel}</span>
                        </Button>

                        {/* User Info */}
                        <div className='pointer'>
                            <Dropdown overlay={widgetMenu}>
                                <div>
                                    <Avatar
                                        size={'small'}
                                        icon={<UserOutlined />}
                                        className='avatarAdditional'
                                    >
                                        {userInfo.first_name && userInfo.first_name.charAt(0)}
                                    </Avatar>
                                    <span className={collapsed ? 'mr-10' : 'mr-10 noShow'}>{userInfo.first_name}</span>
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                </Header>
                {/* Breadcrumb moved outside of Header, now on its own line */}


                <Content
                    className="content"
                >
                    <Row justify="center" className='h-full'>
                        <Col span={24} style={{
                            borderRadius: borderRadiusLG,
                        }}>
                            {dataVisible === INTRODUCTION &&
                                <Introduction />
                            }
                            {dataVisible === GENERAL &&
                                <GeneralPage />
                            }
                            {dataVisible === CHARGERS &&
                                <ChargerModel />
                            }
                            {dataVisible === MAPS &&
                                <GlMaps />
                            }
                            {dataVisible === POWER_GENERATION &&
                                <PowerGeneration />
                            }
                            {dataVisible === FUEL &&
                                <FuelHistChart />
                            }
                            {dataVisible === SOLAR &&
                                <Solar />
                            }
                            {dataVisible === COMMUNICATION &&
                                <Communication />
                            }
                            {dataVisible === REPORTS &&
                                <Reports />
                            }
                            {dataVisible === CAMERAS && (
                                <CameraCard />
                            )
                            }
                            {dataVisible === ALARMS &&
                                <AlarmHistory />
                            }
                            {dataVisible === STARLINK &&
                                <StarLink />
                            }
                            {dataVisible === DOCS &&
                                <Help />
                            }
                        </Col>
                    </Row>
                </Content>
                {/* <Footer style={{ textAlign: 'center' }}>footer</Footer> */}
            </Layout>

        </Layout >
    );
};
export default SummaryDashboard;
