import React, { useEffect, useState } from 'react';
import { Col, Row, Card, Typography, Space, Tooltip, Select, Spin } from 'antd';
import { InfoCircleOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Pie } from 'react-chartjs-2';
import './ChargerLiveStatus.css';
import { fetchSummaryData } from '../../../redux/reducers/apis';
import dayjs from 'dayjs';
import { setFromDate, setToDate } from '../../../redux/reducers/chargerSlice';

const ChargerPieCard = ({ title, tooltip, value, data, isLoading }) => {
    const [legendPosition, setLegendPosition] = useState('bottom'); // State to control legend position
    const options = {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: legendPosition,
                align: 'center',
            },
            tooltip: {
                enabled: true // Disable default tooltip
            },
            datalabels: { // Display data labels inside the segments
                color: 'black',
                formatter: (value, ctx) => {
                    return ctx.dataset.data[ctx.dataIndex];
                },
                font: {
                    weight: 'bold',
                    size: '14'
                }
            }
        },
        elements: {
            arc: {
                borderWidth: 0 // Set the border width to 0 to remove the border
            }
        },
        radius: '95%',
    };

    return (
        <Card hoverable className="card-custom">
            <Card.Grid hoverable className="card-grid-custom">
                <Tooltip title={tooltip}>
                    <Typography.Text strong className="tooltip-title">
                        {title} <InfoCircleOutlined />
                    </Typography.Text>
                </Tooltip>
                <Typography.Title level={1} className="typography-value">
                    <Space>
                        {isLoading ? <Spin spinning={isLoading} size='large' /> : <span>{value}</span>}
                    </Space>
                </Typography.Title>
                <div className="pie-chart-container">
                    <Pie data={data} options={options} />
                </div>
            </Card.Grid>
        </Card>
    );
};

const ChargerCard = ({ title, icon, tooltip, value, isLoading }) => {
    return (
        <Card hoverable className="card-custom">
            <Card.Grid hoverable={false} className="card-grid-custom">
                <Tooltip title={tooltip}>
                    <Typography.Text strong className="tooltip-title">
                        {title} <InfoCircleOutlined />
                    </Typography.Text>
                </Tooltip>
                <Typography.Title level={1} className="tooltip-value">
                    <Space>
                        {icon && <ThunderboltOutlined />}
                        {isLoading ? <Spin spinning={isLoading} size='large' /> : <span>{value}</span>}
                    </Space>
                </Typography.Title>
            </Card.Grid>
        </Card>
    );
};

const ChargerLiveStatus = () => {
    const chargerData = useSelector(state => state.sites.chargerData);
    const isLoadingChargerData = useSelector(state => state.sites.isLoadingChargerData);
    const chargerCount = chargerData.length;
    const dispatch = useDispatch();
    const fromDate = useSelector((state) => state.charger.fromDate);
    const toDate = useSelector((state) => state.charger.toDate);
    const [selectedFilter, setSelectedFilter] = useState('Today');

    const totalChargers = chargerCount;
    const onlineChargers = chargerCount // Assuming all chargers are online

    const pieChartData = {
        labels: ['Online', 'Offline'],
        datasets: [{
            data: [onlineChargers, Math.max(0, totalChargers - onlineChargers)],
            backgroundColor: ['#e6f2db', '#f2dbdb'],
            borderColor: 'rgb(128,128,128)',
            borderWidth: 0.2,
            labels: ['Online: ' + onlineChargers, 'Offline: ' + (totalChargers - onlineChargers)]
        }]
    };

    const getKWHConsumed = (data) => {
        return data?.reduce((total, session) => total + (session.metrics?.energyConsumed || 0), 0);
    }

    const getFaultsCount = (data) => {
        return data?.filter(connector => connector && connector.connectors && connector.connectors[0] && connector.connectors[0].status === "Faulted")?.length || 0;
    }

    const getChargingSessions = (data) => {
        return data?.filter(session => session.status === "Ended").length;
    }

    const selectedCustomerName = useSelector(state => state.customer.selectedCustomerName);
    const avail_sites = useSelector(state => state.sites.avail_sites);
    const selectedSite = useSelector(state => state.sites.selectedSite);
    const preferences = avail_sites.find((site) => site.name === selectedSite)?.preferences;
    // Check if preferences.power.powercards is defined and not empty
    const shouldRenderTotalChargers = preferences?.charger?.chargercards?.includes("TotalChargers");
    const shouldRenderKWHConsumed = preferences?.charger?.chargercards?.includes("kWhConsumed");
    const shouldRenderFaults = preferences?.charger?.chargercards?.includes("Faults");
    const shouldRenderSessions = preferences?.charger?.chargercards?.includes("Chargingsessions");
    const summaryDataFromResponse = useSelector(state => state.charger.summaryData);
    const summaryDataLoading = useSelector(state => state.charger.summaryDataLoading);

    const getUniqueChargerCount = (data) => {
        const chargerIds = new Set();
        data && data.forEach(item => {
            item.session.forEach(session => {
                chargerIds.add(session.charger.id);
            });
        });
        return chargerIds.size || 0;
    };

    const calculateTotalEnergyConsumed = (data) => {
        let totalEnergyConsumed = 0;
        data && data.forEach(item => {
            item.session.forEach(session => {
                totalEnergyConsumed += session.metrics.energyConsumed || 0;
            });
        });
        return totalEnergyConsumed / 1000;
    };

    useEffect(() => {
        if (selectedSite && avail_sites) {
            const fetchData = () => {
                dispatch(fetchSummaryData({ selectedCustomerName, selectedSite, avail_sites, fromDate, toDate }));
            };
            fetchData();
            const intervalId = setInterval(fetchData, 120000);
            return () => clearInterval(intervalId);
        }
    }, [fromDate, selectedSite]);

    const Options = [
        { value: 'Today', label: 'Today' },
        { value: 'Yesterday', label: 'Yesterday' },
        { value: 'Last 7 Days', label: 'Last 7 Days' },
        { value: 'Last 30 Days', label: 'Last 30 Days' },
    ]

    const handleChange = (value) => {
        setSelectedFilter(value);
        switch (value) {
            case 'Today':
                dispatch(setFromDate(dayjs().startOf('day').toISOString()));
                dispatch(setToDate(dayjs().endOf('day').toISOString()));
                break;
            case 'Yesterday':
                dispatch(setFromDate(dayjs().subtract(1, 'day').startOf('day').toISOString()));
                dispatch(setToDate(dayjs().subtract(1, 'day').endOf('day').toISOString()));
                break;
            case 'Last 7 Days':
                dispatch(setFromDate(dayjs().subtract(7, 'day').startOf('day').toISOString()));
                dispatch(setToDate(dayjs().endOf('day').toISOString()));
                break;
            case 'Last 30 Days':
                dispatch(setFromDate(dayjs().subtract(30, 'day').startOf('day').toISOString()));
                dispatch(setToDate(dayjs().endOf('day').toISOString()));
                break;
            default:
                break;
        }
    }

    const selectDropDown = (
        <div>
            Charger Summary for <Select size='large' style={{ width: 150 }} options={Options} defaultValue={'Today'} placeholder="Select range" onChange={handleChange} />
        </div>
    )

    return (
        <div className='chargerLiveStatus'>
            <Card title={selectDropDown}>
                <Row justify="space-around" gutter={[16, 16]}>
                    {shouldRenderTotalChargers && (
                        <Col xs={24} sm={24} md={12} lg={24 / preferences?.charger?.chargercards.length}  // Dynamically adjust based on the number of columns
                            xl={24 / preferences?.charger?.chargercards.length}  // Ensures equal width for all columns
                        >
                            <ChargerCard
                                isLoading={isLoadingChargerData}
                                title="Total Chargers"
                                tooltip="Total Number of Chargers in the Site"
                                value={chargerCount}
                            />
                        </Col>
                    )}
                    {shouldRenderKWHConsumed && (
                        <Col xs={24} sm={24} md={12} lg={24 / preferences?.charger?.chargercards.length}  // Dynamically adjust based on the number of columns
                            xl={24 / preferences?.charger?.chargercards.length}  // Ensures equal width for all columns
                        >
                            <ChargerCard
                                isLoading={summaryDataLoading}
                                title="KWH consumed"
                                tooltip={`Total KWH consumed ${selectedFilter}`}
                                value={calculateTotalEnergyConsumed(summaryDataFromResponse || [])}
                            />
                        </Col>
                    )}
                    {shouldRenderFaults && (
                        <Col xs={24} sm={24} md={12} lg={24 / preferences?.charger?.chargercards.length}  // Dynamically adjust based on the number of columns
                            xl={24 / preferences?.charger?.chargercards.length}  // Ensures equal width for all columns
                        >
                            <ChargerCard
                                isLoading={summaryDataLoading}
                                title="Faults"
                                tooltip={`Total number of faults encountered for ${selectedFilter}`}
                                value={getFaultsCount(summaryDataFromResponse && summaryDataFromResponse[0]?.connector || [])}
                            />
                        </Col>
                    )}
                    {shouldRenderSessions && (
                        <Col xs={24} sm={24} md={12} lg={24 / preferences?.charger?.chargercards.length}  // Dynamically adjust based on the number of columns
                            xl={24 / preferences?.charger?.chargercards.length}  // Ensures equal width for all columns
                        >
                            <ChargerCard
                                isLoading={summaryDataLoading}
                                title="Charging sessions"
                                tooltip={`Number of Charging sessions Info for ${selectedFilter}`}
                                value={getChargingSessions(summaryDataFromResponse && summaryDataFromResponse[0]?.session || [])}
                            />
                        </Col>
                    )}
                </Row>
            </Card>
        </div >
    );
};

export default ChargerLiveStatus;
